.logo_header {
  width: 150px;
}

.title_direction {
  background-color: #eeeded;
}

.footer {
  margin-top: 90px;
  height: 120px;
  background-color: #000000;
  color: #ffffff;
}

.button_end_input {
  border-radius: 0px 4px 4px 0px !important;
  height: 48px;
  box-shadow: none !important;
}

.map_layout {
  border: 1px solid #eeeded;
  width: 100%;
  height: 327px;
  margin: 24px !important;
}

.disabled_title {
  color: #C8C8C8 !important;
}

.button_height {
  height: 48px;
}
