/* common utility */
::placeholder {
  font-size: 12px;
}

.padding-item-0 {
  padding: 0px 24px !important;
}

.padding-item-6 {
  padding: 6px 24px !important;
}

/* Padding */
.p-0 {
  padding: 0px !important;
}

.p-5 {
  padding: 5px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-15 {
  padding: 15px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

/* Margin */
.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.mt-36 {
  margin-top: 36px !important;
}

.mt-48 {
  margin-top: 48px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-75 {
  margin-bottom: 75px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

/* Font size */
.font-10 {
  font-size: 10px;
}

.font-12 {
  font-size: 12px;
}

.font-16 {
  font-size: 16px;
}

.font-17 {
  font-size: 17px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-23 {
  font-size: 23px;
}

.font-25 {
  font-size: 25px;
}

/* Color */
.color-success {
  color: #18C090 !important;
}

.color-infor{
  color: #30C0F0 !important;
}

.color-danger {
  color: #FF483C !important;
}

.color-light {
  color: #FFFFFF !important;
}

.color-dark {
  color: #000000 !important;
}
